<template>
  <div class="flip-container">
    <div class="login-screen">
      <span class="main-logo" :style="{ backgroundImage: `url(${config.SITE_LOGO_URL})` }"></span>
      <div class="login-block row middle-xs">
        <span
          class="loginimg"
          :style="{ backgroundImage: `url(${config.LOGIN_PAGE_IMAGE_URL})` }"
        ></span>
        <div class="login-info">
          <span class="ftitle">Forgot Password</span>
          <div v-if="submitted" class="submitted">
            Request submitted.
            <br /><br />
            If your email address is valid, you will receive a link to reset your password shortly.
          </div>
          <div v-else>
            <label class="flabel">Email Address</label>
            <k-text-input
              ref="email"
              class="login-input"
              :required="true"
              :default-focus="true"
              @change="onChangeEmail"
              @keyup="onKeyUp"
              @validation="onValidation"
            />
            <k-button :size="4" label="Submit" @click="onClickSubmit" />
          </div>
        </div>
        <div v-if="error" class="error">Error: {{ error }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

import { isBlank } from 'adready-api/helpers/common';
import validationsMixin from 'adready-vue/components/mixins/validations-mixin';
import config from '~/config';

export default {
  name: 'ForgotPassword',

  mixins: [validationsMixin.events],

  props: {
    err: {
      required: false,
      type: String,
      default: undefined,
    },
  },

  data() {
    return {
      email: null,
      errorMsg: null,
      submitted: false,
      appId: this.$route.query.app,
      mode: this.$route.query.mode,
      config,
    };
  },

  computed: {
    error() {
      if (!isBlank(this.errorMsg)) {
        return this.errorMsg;
      }
      if (!isBlank(this.err)) {
        return this.err;
      }
      return null;
    },
  },

  methods: {
    onChangeEmail(val) {
      this.email = val;
    },

    onKeyUp(val, e) {
      if (e.key && e.key.includes('Enter')) {
        this.onClickSubmit();
      }
    },

    onClickSubmit() {
      if (isBlank(this.email)) {
        this.errorMsg = 'Email is required';
        this.$refs.email.focus();
        return;
      }

      this.errorMsg = null;

      axios
        .post(
          `${config.ADREADY_URL}/api/auth/forgot_password?app=${this.appId}&mode=${this.mode}`,
          {
            email: this.email,
            hostname: window.location.origin,
          }
        )
        .then(() => {
          this.submitted = true;
        })
        .catch((error) => {
          this.errorMsg = 'failed to request password reset';
          // eslint-disable-next-line no-console
          console.error(this.errorMsg, error);
          if (this.$sentry) {
            if (error._reported !== true) {
              this.$sentry.captureException(error);
              error._reported = true;
            }
          }
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.flip-container {
  background-color: #131619;
  width: 100vw !important;
  height: 100vh;
  margin-bottom: -40px;
}
::v-deep .form-input .form-input-field {
  width: 100%;
  padding: 15px 20px;
  box-sizing: border-box;
  font-size: 12px;
  border: 1px solid #dadada !important;
  background-color: #fff !important;
  color: #000 !important;
}
::v-deep .k-btn.btn-size-4 {
  padding: 0 !important;
  background-color: var(--defaultprimarycolor) !important;
  font-size: 14px !important;
  // padding: 20px 18px !important;
  border-radius: 0 !important;
  border: 0px;
  cursor: pointer;
  appearance: none;
  color: #fff !important;
  text-transform: uppercase;
  letter-spacing: 0.15em !important;
  line-height: 1.2em;
  display: inline-block;
  font-weight: 400 !important;
  transition: all 0.6s ease;
  text-align: center;
  min-width: 214px !important;
  user-select: none;
  span.label {
    padding: 14px 22px !important;
  }
  &:hover {
    background-color: #424242 !important;
    color: #fff !important;
  }
}
.ftitle {
  padding-bottom: 30px;
}
.submitted {
  width: 200px;
  text-align: left;
  color: #9c9c9c;
}
</style>
